import { Box, Text } from "@chakra-ui/react";
import T from "../../../Core/Translations";
import useSpeakingLanguagesDropdown from "../Hooks";
import { Select, chakraComponents, MultiValueRemoveProps, GroupBase } from "chakra-react-select";

// chakra-react-select does not provide a possibility to position with the tab and select the delete buttons on selected elements.
// We're doing this trick here. Extending the MultiValueRemove component with tabIndex=0, so it can be focused by the keyboard.
// And then when focused if Enter is pressed we convert this to a click (because the component handles only clicks).
// TODO: this is good to be moved to a shared place where it can be reused in other multi select that need it too.
const MultiValueRemove = ({ ...props } : MultiValueRemoveProps<{
  value: string;
  label: string;
}, true, GroupBase<{
  value: string;
  label: string;
}>>) => {
  props.innerProps.tabIndex = 0;
  props.innerProps.onKeyDown = (e) => {
    if (e.key == 'Enter') {
      const target = (e.target as any); // cast to any because EventTarget is not defined in full and we know we have click on this element
      target.click && target.click(); // simulate click
    }
  }
  return <chakraComponents.MultiValueRemove {...props} />
};

const SpeakingLanguagesDropdown = (props: {
  mt?: number;
  title?: string;
  selectedLanguages?: { value: string; label: string }[];
  initialLanguages?: { value: string; label: string }[];
  initialLangCodes?: string[];
  onChange: (languages: { label: string; value: string }[]) => void;
  onLoaded: () => void;
}) => {
  const { languagesList, onChangeSelectedLanguages } =
    useSpeakingLanguagesDropdown(props);

  return (
    <Box mt={props.mt ? props.mt : 0}>
      {props.title ? (
        <Text fontSize={14} fontWeight={400} pb={2}>
          {props.title ? props.title : T.get("Spoken languages")}
        </Text>
      ) : null}
      <Select
        isMulti
        useBasicStyles
        defaultValue={props.initialLanguages}
        closeMenuOnSelect={true}
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            _light: {
              backgroundColor: "white",
            },
            _dark: {
              backgroundColor: "black",
            },
            shadow: "0px 4px 4px 0px rgba(74, 74, 74, 0.04)",
          }),
        }}
        components={{MultiValueRemove}}
        options={languagesList}
        placeholder={T.get("Add spoken languages to session")}
        onChange={onChangeSelectedLanguages}
        value={props.selectedLanguages}
      />
    </Box>
  );
};

export default SpeakingLanguagesDropdown;
