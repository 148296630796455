import React, { useState, useEffect, useRef } from 'react';
import { Device, AudioProcessor } from '@twilio/voice-sdk';

let audioContext = new AudioContext();

declare global {
    interface Window {
        onAudioIncoming: (data: Int16Array, sampleRate: number) => void;
    }
}

/*window.onAudioIncoming = (data: Int16Array, sampleRate: number) => {  
    console.log('Audio incoming:', data, sampleRate);    
}*/   

const ConferenceListener: React.FC = () => {
  const device = useRef<Device | null>(null);

  const float32ToS16 = (input: any) => {
     const output = new Int16Array(input.length);
     for (let i = 0; i < input.length; i++) {
         const sample = Math.max(-1, Math.min(1, input[i]));
         output[i] = sample < 0 ? sample * 0x8000 : sample * 0x7FFF;
     }
     return output.buffer;
  }

  const sendForProcessing = (stream: MediaStream) => {

    const sampleRate = audioContext.sampleRate;
    const bufferSize = 4096;   

    const processor = audioContext.createScriptProcessor(bufferSize, 1, 1);
    processor!.onaudioprocess = (event: any) => {           
        const input = event.inputBuffer.getChannelData(0);
        const output = event.outputBuffer.getChannelData(0);
        output.set(input);
        const s16Data = float32ToS16(input);           
        console.log("Data", new Int16Array(s16Data));
        if (window.onAudioIncoming) {                
            window.onAudioIncoming(new Int16Array(s16Data), sampleRate);
        }
    };

    const source = audioContext.createMediaStreamSource(stream);
    const destination = audioContext.createMediaStreamDestination();        
    source!.connect(processor);
    processor!.connect(destination);  

  }

  const setupDevice = async () => {
    try {

      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      const conferenceId = searchParams.get('conferenceId');  

      if (!token || !conferenceId) {
        console.error('Token or conferenceId not found in URL');
        return;
      }

      const response = await fetch(`https://api.staging.ai-pro.boostlingo.com/sessions/twilio?token=${token}&conferenceId=${conferenceId}`);
      const data = await response.json();

      device.current = new Device(data.token);        

      const call = await device.current!.connect({
          params: {
              To: conferenceId
          }
      });

      call.on('error', (error) => {
            console.error('Call error:', error);
      });

      call.on('disconnect', () => {
            console.log('Call disconnected');
      });

      call.on('accept', () => {
            console.log('Call accepted');
      });

      call.on('audio', async () => {
        console.log('audio ready');
        const remoteStream = await call.getRemoteStream();

        if (remoteStream) {
          sendForProcessing(remoteStream);
        }
      });

      call.on('ringing', () => {
        console.log('Call ringing');
      });

      call.mute(true);
      
    } catch (error) {
      console.error('Failed to setup Twilio Device:', error);
    }
  }

  useEffect(() => {

    setupDevice();

    window.addEventListener('click', () => {
        if (audioContext.state === 'suspended') {
          audioContext.resume();
        }
      });
  }, []);

  return (
    <div>
      {<p>Connected to the conference in listen-only mode.</p>}
    </div>
  );
};

export default ConferenceListener;